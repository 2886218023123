var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app modal-vue miner-info pools"},[_c('CWrapper',{staticClass:"bg-bg-gray pt-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort pr-0"},[_c('div',{staticClass:"c-title pb-4"},[_vm._v(_vm._s(_vm.$t("Pools")))]),_c('form',{staticClass:"c-miners-filtration d-flex"},[_c('CSelect',{attrs:{"options":['12', '25', '50']},on:{"update:value":_vm.updateValue}})],1)]),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[(_vm.loadingPools)?_c('p',{staticClass:"text-center"},[_c('CSpinner',{attrs:{"grow":"","size":"md"}})],1):_vm._e(),_c('CDataTable',{staticClass:"bg-white rounded-md border-gray_secondary cursor-pointer",attrs:{"color":"main_black","items":_vm.pools.data,"fields":_vm.fields,"sorter":""},scopedSlots:_vm._u([{key:"remove",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex justify-content-around h-100"},[_c('CButton',{staticClass:"edit-icon border-none p-0",on:{"click":function($event){return _vm.$store.commit('miners/triggerEdit', {
                    item: item,
                    page: 'Pools',
                    status: 'edit',
                  })}}},[_c('img',{staticClass:"cursor-pointer m-0 p-0 btn",attrs:{"src":require("@/assets/icons/users/edit_icon.svg")}})]),_c('img',{staticClass:"btn m-0 p-0",attrs:{"src":require("@/assets/icons/users/remove_icon.svg")},on:{"click":function($event){return _vm.deletePool(item)}}})],1)]}}])}),_c('CPagination',{attrs:{"activePage":_vm.page,"pages":_vm.$store.state.miners.pools.meta
              ? _vm.$store.state.miners.pools.meta.last_page
              : 0,"doubleArrows":false,"size":"lg","align":"center"},on:{"update:activePage":[function($event){_vm.page=$event},_vm.updatePage],"update:active-page":function($event){_vm.page=$event}}})],1)],1),_c('CModal',{staticClass:"text-main_black bg-milk_mix",attrs:{"title":"Edit","color":"lightGray","show":_vm.warningModal},on:{"update:show":function($event){_vm.warningModal=$event}}},[_c('CForm',[_c('p',{staticClass:"border-0 mb-2"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v("Farm name")]),_c('CInput',{attrs:{"id":"location"}})],1)])],1),_c('CModal',{staticClass:"text-main_black bg-milk_mix",attrs:{"title":"Delete","color":"lightGray","show":_vm.errorModal},on:{"update:show":function($event){_vm.errorModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
              var item = ref.item;
return [_c('span')]}}])},[_c('CForm',[_c('div',{staticClass:"border-0 mb-0 text-center"},[_c('img',{attrs:{"src":require("@/assets/icons/ic_fail.svg"),"alt":""}}),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"w-75 m-auto font-weight-normal"},[_vm._v(" Connected pool can not be deleted ")])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }