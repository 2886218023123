<template>
  <div class="c-app modal-vue miner-info pools">
    <CWrapper class="bg-bg-gray pt-2">
      <div
        class="d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort pr-0"
      >
        <div class="c-title pb-4">{{ $t("Pools") }}</div>
        <form class="c-miners-filtration d-flex">
          <CSelect :options="['12', '25', '50']" @update:value="updateValue" />
        </form>
      </div>
      <CRow>
        <CCol md="12">
          <p class="text-center" v-if="loadingPools">
            <CSpinner grow size="md" />
          </p>
          <CDataTable
            color="main_black"
            :items="pools.data"
            :fields="fields"
            class="bg-white rounded-md border-gray_secondary cursor-pointer"
            sorter
          >
            <template #remove="{ item }">
              <td class="d-flex justify-content-around h-100">
                <CButton
                  @click="
                    $store.commit('miners/triggerEdit', {
                      item,
                      page: 'Pools',
                      status: 'edit',
                    })
                  "
                  class="edit-icon border-none p-0"
                >
                  <img
                    src="@/assets/icons/users/edit_icon.svg"
                    class="cursor-pointer m-0 p-0 btn"
                  />
                </CButton>
                <img
                  src="@/assets/icons/users/remove_icon.svg"
                  class="btn m-0 p-0"
                  @click="deletePool(item)"
                />
              </td>
            </template>
          </CDataTable>
          <CPagination
            :activePage.sync="page"
            :pages="
              $store.state.miners.pools.meta
                ? $store.state.miners.pools.meta.last_page
                : 0
            "
            :doubleArrows="false"
            @update:activePage="updatePage"
            size="lg"
            align="center"
          />
        </CCol>
      </CRow>
      <CModal
        title="Edit"
        color="lightGray"
        class="text-main_black bg-milk_mix"
        :show.sync="warningModal"
      >
        <CForm>
          <p class="border-0 mb-2">
            <label for="first_name">Farm name</label>
            <CInput id="location"></CInput>
          </p>
        </CForm>
      </CModal>
      <CModal
        title="Delete"
        color="lightGray"
        class="text-main_black bg-milk_mix"
        :show.sync="errorModal"
      >
        <CForm>
          <div class="border-0 mb-0 text-center">
            <img src="@/assets/icons/ic_fail.svg" alt="" />
            <div class="mt-4">
              <p class="w-75 m-auto font-weight-normal">
                Connected pool can not be deleted
              </p>
            </div>
          </div>
        </CForm>
        <template #footer="{ item }">
          <span />
        </template>
      </CModal>
    </CWrapper>
  </div>
</template>

<script>
import request from "../../plugins/request";
import { mapActions, mapState } from "vuex";

const fields = [
  {
    key: "address",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
    title: "Pool address",
  },
  {
    key: "name",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  {
    key: "description",
    filter: false,
    sorter: false,
    _style: "min-width:100px;",
  },
  {
    key: "remove",
    filter: false,
    label: "",
    _style: "width: 10%;",
  },
];

export default {
  name: "Pool",
  components: {},
  data() {
    return {
      items: [],
      errorModal: false,
      fields,
      page: parseInt(this.$route.params.id) ? parseInt(this.$route.params.id) : 1,
      warningModal: false,
      loadingPools: true,
    };
  },
  created() {
    // this.fetchedPools();
    this.GET_POOL({
      page: this.page ? this.page : 1,
      pageUrls: { ...this.$route.query },
    }).then(() => {
      this.loadingPools = false;
    });
    this.curPage();
  },
  computed: {
    ...mapState("miners", ["pools"]),
  },
  methods: {
    ...mapActions("miners", ["GET_POOL"]),
    curPage() {
      this.$emit("curPage", "Pools");
      this.$emit("curPools", this.fetchedPools);
    },
    fetchedPools() {
      request.get("/api/pools/all").then((res) => {
        res = res.data.data.pools.map((obj) => {
          return { ...obj };
        });
        this.items = res;
        this.loadingPools = false;
      });
    },
    updateValue(value) {
      const newObj = { ...this.$route.query };
      newObj["per_page"] = value;
      this.GET_POOL({ page: this.page ? this.page : 1, pageUrls: newObj }).then(() => {
        this.loadingPools = false;
        this.$router.push({
          name: "Pool",
          query: newObj,
        });
      });
    },
    deletePool(item) {
      let isSure = confirm("Are you sure?");
      if (!isSure) return;
      this.loadingUsers = true;
      request
        .delete(`/api/pools/${item.id}`)
        .then(() => {
          this.GET_POOL({ page: this.page ? this.page : 1 });
        })
        .catch((err) => {
          if (err.response.status == 500) {
            this.errorModal = true;
          }
        });
    },
    updatePage(e) {
      this.$route.params.id = e;
      this.$router.push({ ...this.$route });
    },
  },
};
</script>

<style scoped lang="sass">
.edit-icon:hover svg path
  fill: #0F5B84

.delete-icon:hover svg path
  fill: #EC5252
  stroke: #EC5252
</style>
